import React from 'react'
import Layout from '../components/layout'


export default function CommentPolicyPage() {
  return (
    <Layout>
      <h1 className="main-heading">Comment Policy</h1>
      <p>We welcome all constructive comments and feedback. Off-topic comments will be removed and trolls will be shadow banned.</p>
    </Layout>
  )
}